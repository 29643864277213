@tailwind base;
@tailwind components;
@tailwind utilities;




@layer components{


  .App {
    @apply flex justify-start flex-row transition-all duration-500 ease-in-out  ;
  }

  .button{
    @apply px-8 py-4   hover:opacity-70 transition-all ease-in-out duration-500 bg-accent-color text-white rounded-md font-semibold shadow flex justify-center items-center;
  } 

  .Swiper-Slide{
    @apply block w-full h-full;
  }

  ::-webkit-scrollbar {
    @apply w-2;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-darkprimary-color
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-accent-color;
  
    
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
  }

  
 
  
}